<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.search_content"
        :class="$style.input"
        v-bind="layout"
        allow-clear
        label="搜索用户"
        :placeholder="placeholder"
        @pressEnter="onSearch"
      >
        <a-select slot="addonBefore" v-model="search.search_type" style="width: 100px">
          <a-select-option :value="1"> 用户昵称 </a-select-option>
          <a-select-option :value="2"> 手机号码 </a-select-option>
          <a-select-option :value="3"> 真实姓名 </a-select-option>
          <i slot="suffixIcon" class="ri-arrow-down-s-line suffix" />
        </a-select>
      </BjInput>
    </BjSearch>
    <div :class="$style.table">
      <p :class="$style.title">用户列表</p>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #user="item">
          <img :class="$style.avatar" :src="item.avatar" />
          <span :class="$style.name">{{ item.nickname }}</span>
        </template>
        <template #visit="item">
          <span :class="item.status === 2 ? null : $style.error">{{ item.status === 2 ? '正常' : '禁止' }}</span>
        </template>
        <template #comment>
          <span :class="$style.error">禁止</span>
        </template>
        <template #action="item">
          <bj-link v-permission="'user.manage.blacklist-manage'" type="primary" @click="onVisit(item)">
            {{ item.status === 3 ? '允许访问' : '禁止访问' }}
          </bj-link>
          <bj-link v-permission="'user.manage.blacklist-manage'" type="primary" @click="onComment(item)">
            允许评论
          </bj-link>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { userListService } from '@/service'

const service = new userListService()

export default {
  name: 'Home',
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      page: 1,
      pageSize: 20,
      total: 0,
      search: {
        search_type: 1,
        search_content: null,
      },
      data: [],
      loading: false,
    }
  },
  computed: {
    placeholder() {
      return this.search.search_type === 1
        ? '请输入用户昵称'
        : this.search.search_type === 2
        ? '请输入手机号码'
        : '请输入真实姓名'
    },
    columns() {
      return [
        {
          title: '用户',
          scopedSlots: {
            customRender: 'user',
          },
        },
        { title: '手机号码', dataIndex: 'telephone', customRender: value => value || '-' },
        {
          title: '真实姓名',
          dataIndex: 'real_name',
          customRender: value => value || '-',
        },
        {
          title: '访问权限',
          scopedSlots: {
            customRender: 'visit',
          },
        },
        {
          title: '评论权限',
          scopedSlots: {
            customRender: 'comment',
          },
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    onSearch() {
      this.page = 1
      this.getList()
    },
    async onVisit(_item) {
      let state = 1
      if (_item.status === 2) {
        state = 3
      }
      if (_item.status === 3) {
        state = 2
      }
      try {
        await service.saveStatus({
          user_ids: [_item.id],
          status: state,
        })
        this.page = 1
        this.$message.success('修改成功')
        this.getList()
      } catch (e) {}
    },
    async onComment(_item) {
      try {
        await service.saveStatus({
          user_ids: [_item.id],
          status: 1,
        })
        this.$message.success('修改成功')
        this.page = 1
        this.getList()
      } catch (e) {}
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
          ...this.search,
          block: 1,
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    init() {
      this.search = {
        search_type: 1,
        search_content: null,
      }
      this.onSearch()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  :global {
    .ant-select-arrow {
      top: 40%;
    }
  }

  .input {
    :global {
      .ant-input-group-wrapper {
        position: relative;
        top: 4px;
      }

      .ant-select-selection__rendered {
        margin-left: 15px;
        color: #5c5c5c;
      }
    }
  }

  .table {
    min-height: calc(100vh - 252px);
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;

    .title {
      height: 36px;
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;
      line-height: 36px;
    }

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      object-fit: cover;
    }

    .name {
      margin-left: 10px;
      color: @primary-color;
    }
  }

  .error {
    color: @error-color;
  }
}
</style>
